<template>
  <div>
    <!--zh-cn-->
    <div v-if="lang == 'zh-CN'">

    </div>

    <!--en-->
    <div v-if="lang == 'en'">

    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
    components: {},
    props: {},
    data() {
      return {
        lang: this.$route.params.lang || 'en'
      }
    },
    computed: {},
    methods: {},
    mounted() {

    },
  }
</script>

<style>

</style>
